import { apiClient } from '@/api/api.client';
import { TEventSignUpFormResponse } from '@/helpers/types/event/event.types';
import { GenericAbortSignal } from 'axios';
import {
  IAuthTokens,
  ICitiesRequestData,
  ICity,
  ICountry,
  IMunicipalityRequestData,
  IRegion,
  IRegionsRequestData,
} from '@/services/AuthService/AuthServiceTypes';
import { IRecover } from '@/services/ProgramService/ProgramServiceTypes';

export class SignUpApi {
  public static async checkIsEmailExist(email: string): Promise<void> {
    await apiClient.post('auth/check-email', { email });
  }
  public static async getSignUpForm(slug: string): Promise<TEventSignUpFormResponse> {
    const res = await apiClient.get(`/event-sign-up/${slug}/form`);
    return res.data;
  }
  public static async getCountryOptions(
    search: string,
    signal?: GenericAbortSignal | undefined
  ): Promise<ICountry[]> {
    const res = await apiClient.get(`/countries`, { params: { search }, signal });
    return res.data;
  }
  public static async getRegionOptions(
    data: IRegionsRequestData,
    signal?: GenericAbortSignal | undefined
  ): Promise<IRegion[]> {
    const res = await apiClient.get(`/countries/${data.countryId}/regions?search=${data.search}`, {
      signal,
    });
    return res.data;
  }
  public static async getCityOptions(
    data: ICitiesRequestData,
    signal?: GenericAbortSignal | undefined
  ): Promise<ICity[]> {
    const res = await apiClient.get(
      `/countries/${data.countryId}/regions/${data.regionId}/cities?search=${data.search}&regionName=${data.regionName}`,
      {
        signal,
      }
    );
    return res.data;
  }
  public static async getMunicipalityOptions(
    data: IMunicipalityRequestData,
    signal?: GenericAbortSignal | undefined
  ): Promise<{ area: string }[]> {
    const res = await apiClient.get(
      `/countries/${data.countryId}/regions/${data.regionId}/cities/${data.cityId}`,
      { params: { search: data.search, regionName: data.regionName }, signal }
    );
    return res.data;
  }
  public static async validateStep(
    slug: string,
    stepIndex: number,
    form: Record<string, any>
  ): Promise<void> {
    await apiClient.post(`/event-sign-up/${slug}/${stepIndex}/validate-step`, form);
  }
  public static async signUp(
    slug: string,
    body: Record<string, any>
  ): Promise<{
    isSuccess: boolean;
    accessToken: string;
    refreshToken: string;
  }> {
    const res = await apiClient.post(`/event-sign-up/${slug}`, body);
    return res.data;
  }
  public static async recover(payload: IRecover): Promise<void> {
    await apiClient.post('/auth/request-password-recovery', payload);
  }
}
