import { Commit } from 'vuex';
import { types } from './mutations';
import { EventService } from '@/services';
import router from '@/router';
import {
  ICancelInvitationEventRequestData,
  IEvent,
  IMyEventList,
  IReadEventDocumentRequestData,
  ISearchProgramRequestData,
  ISpeakerCard,
  IToggleFavoriteRequestData,
  IUserEventDocumentRequestParams,
} from '@/services/EventService/EventServiceTypes';
import { useAuthStore } from '@/store/pinia/auth/auth.store';
import { EX_$Toaster } from '@/classes/content';
import { AxiosError } from 'axios';
import { NewsApi } from '@/api/apis/NewsApi';

export default {
  async AGetEventList({ commit }: { commit: Commit }, search?: string): Promise<void> {
    try {
      await EventService.getEventList(search).then((res: IEvent[]) => {
        commit(types.SET_EVENT_LIST, res);
      });
    } catch (e) {
      console.log(e);
    }
  },
  async AGetEventInfo({ commit }: { commit: Commit }, slug: string): Promise<void> {
    try {
      const { news } = await NewsApi.getNewsList(true);
      const eventInfo = await EventService.getEventInfo(slug);

      commit(types.SET_EVENT_INFO, eventInfo);

      commit('setEventNews', news);
    } catch (error) {
      const err = error as AxiosError;
      // @ts-ignore
      if (err.response.status === 403) {
        router.replace('/');
      }
      // @ts-ignore
      if (err.response.status === 404) {
        router.replace('/404');
      }
      console.log(error);
    }
  },

  async updateEventSpeakersOrder(
    { commit }: { commit: Commit },
    {
      speakers,
      slug,
    }: {
      speakers: ISpeakerCard[];
      slug: string;
    }
  ): Promise<void> {
    try {
      const res = await EventService.updateEventSpeakersOrder(speakers, slug);
      commit('updateSpeakersOrder', res);
      EX_$Toaster.success('Спикеры успешно обновлены');
    } catch (error) {
      EX_$Toaster.error('Не удалось сохранить');
    }
  },

  async AGetMyEventList({ commit }: { commit: Commit }): Promise<void> {
    try {
      if (!useAuthStore().isLoggedIn) return;
      await EventService.getMyEventList().then((res: IMyEventList) => {
        commit(types.SET_MY_EVENT_LIST, res.myEvents);
      });
    } catch (e) {
      console.log(e);
    }
  },

  async AAddEventToFavoriteEvents(
    { commit }: { commit: Commit },
    body: IToggleFavoriteRequestData
  ): Promise<void> {
    try {
      const res = await EventService.addEventToFavoriteEvents(body);
      commit(types.ADD_FAVORITE_EVENT, res);
    } catch (e) {
      console.log(e);
    }
  },

  async ARemoveEventToFavoriteEvents(
    { commit }: { commit: Commit },
    eventUUID: string
  ): Promise<void> {
    try {
      await EventService.removeEventFromFavoriteEvents(eventUUID);
      commit(types.DELETE_FAVORITE_EVENT, eventUUID);
    } catch (e) {
      console.log(e);
    }
  },

  async AGetFavoriteEvents({ commit }: { commit: Commit }): Promise<void> {
    try {
      if (!useAuthStore().isLoggedIn) return;
      await EventService.getFavoriteEvents().then((res: Array<IEvent>) => {
        commit(types.SET_FAVORITE_EVENTS, res);
      });
    } catch (e) {
      console.log(e);
    }
  },

  async AGetUserEventDocument(
    { commit }: { commit: Commit },
    params: IUserEventDocumentRequestParams
  ): Promise<void> {
    try {
      if (!useAuthStore().isLoggedIn) return;
      EventService.getUserEventDocuments(params).then((res) => {
        commit(types.SET_USER_DOCUMENTS_READ, res);
      });
    } catch (e) {
      console.log(e);
    }
  },

  async AReadEventDocument(
    { commit }: { commit: Commit },
    body: IReadEventDocumentRequestData
  ): Promise<void> {
    try {
      const res = await EventService.readEventDocument(body);
      commit(types.READ_EVENT_DOCUMENT, res);
    } catch (e) {
      console.log(e);
    }
  },

  async ACancelInvitationEvent(
    { commit }: { commit: Commit },
    body: ICancelInvitationEventRequestData
  ): Promise<void> {
    try {
      const res = await EventService.cancelInvitationEvent(body);

      if (res.isSuccessDeclined) {
        commit(types.CANCEL_INVITATION, body.invitationId);
      }
    } catch (e) {
      EX_$Toaster.error('Не удалось отменить приглашение');
    }
  },

  async AGetInvitationsEvent({ commit }: { commit: Commit }): Promise<void> {
    try {
      EventService.getInvitationsEvent().then((res) => {
        commit(types.SET_INVITATIONS_EVENT, res);
      });
    } catch (e) {
      console.log(e);
    }
  },

  ALogoutEvent({ commit }: { commit: Commit }): void {
    commit(types.LOGOUT_EVENT);
  },

  async AGetRecommended({ commit }: { commit: Commit }): Promise<void> {
    try {
      if (!useAuthStore().isLoggedIn) return;
      const res = await EventService.getRecommended();
      commit(types.SET_RECOMMENDED_EVENTS, res);
    } catch (e) {
      console.log(e);
    }
  },

  async ASearchProgram(
    { commit }: { commit: Commit },
    body: ISearchProgramRequestData
  ): Promise<void> {
    try {
      const res = await EventService.SearchProgram(body);
      commit(types.SET_SEARCH_PROGRAM, res);
    } catch (e) {
      console.log(e);
    }
  },

  async AUploadImageEvent({ commit }: { commit: Commit }, body: FormData): Promise<void> {
    commit(types.SET_FETCHING_UPLOAD_IMAGE_EVENT, true);
    try {
      const res = await EventService.uploadImageEvent(body);
      commit(types.SET_UPLOAD_IMAGE_EVENT, res.url);
      commit(types.SET_FETCHING_UPLOAD_IMAGE_EVENT, false);
    } catch (e) {
      commit(types.SET_FETCHING_UPLOAD_IMAGE_EVENT, false);
      console.log(e);
    }
  },

  AClearImageEvent({ commit }: { commit: Commit }): void {
    commit(types.CLEAR_IMAGE_EVENT);
  },

  ARemoveFavoriteProgram({ commit }: { commit: Commit }, programUUID: string): void {
    commit(types.DELETE_FAVORITE_PROGRAM, programUUID);
  },
};
