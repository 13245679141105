<template>
  <div v-if="note" class="mt-0.5 text-xs">
    {{ note }}
  </div>
</template>

<script lang="ts">
export default { name: 'SignUpModalNote' };
</script>

<script setup lang="ts">
defineProps<{
  note?: string | null;
}>();
</script>

<style scoped lang="scss"></style>
