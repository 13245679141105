<template>
  <AppModal :is-show-modal="true" @toggleModal="$emit('close-modal')" :is-hide-icon-close="true">
    <div
      class="app-modal-registration-success__card"
      :style="getBackgroundImage(eventHeroImageUrl)"
    >
      <div class="app-modal-registration-success__card-shadow">
        <AppText
          class="app-modal-registration-success__event-name"
          type="fourthTitle"
          color="button-title"
          :text="eventTitle"
        />
      </div>
    </div>

    <div class="app-modal-registration-success__title">
      <AppText type="title" :text="'Вы успешно зарегистрированы на событие'" />
    </div>
    <div class="app-modal-registration-success__subtitle">
      <AppText
        type="main-book"
        color="secondary"
        :text="'Все события, на которое вы зарегистрировались, доступны в личном кабинете.'"
      />
    </div>

    <AppButton text="Ура!" :is-disabled="false" @click="$emit('close-modal')" />
  </AppModal>
</template>

<script lang="ts">
export default { name: 'SuccessfulModal' };
</script>

<script lang="ts" setup>
import AppModal from '@/ui/components/modals/app-modal/AppModal.vue';
import { AppText, AppButton } from '@/ui/components';
import { getBackgroundImage } from '@/helpers/methods/image.methods';

const props = defineProps<{
  eventTitle: string;
  eventHeroImageUrl: string;
}>();

const emit = defineEmits<{
  (e: 'close-modal'): void;
}>();
</script>

<style lang="scss" scoped>
@import '~@/ui/styles/colors/index';
.app-modal-registration-success {
  &__card {
    max-width: 600px;
    height: 344px;
    position: relative;
    overflow: hidden;
    border-radius: 21px;
    &-shadow {
      width: 100%;
      height: 100%;
      position: relative;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.77) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }
  &__event-name {
    top: 30px;
    left: 30px;
    max-width: 375px;
    position: absolute;
  }
  &__time {
    position: absolute;
    bottom: 30px;
    left: 30px;
    display: flex;
    align-items: center;
    max-width: content-box !important;
    border-radius: 10px;
    padding: 10px 13px;
    background-color: $WHITE;
  }
  &__title {
    max-width: 508px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    margin: 20px auto;
    .app-text {
      font-size: 32px !important;
      @media (max-width: 768px) {
        font-size: 24px !important;
      }
    }
  }
  &__subtitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
    .app-text {
      @media (max-width: 768px) {
        font-size: 14px !important;
        line-height: 20px;
      }
    }
  }
}
</style>
