<template>
  <div class="w-full">
    <slot name="control" :startSignUp="startSignUp" />

    <StartModal
      v-if="isShowModal.start && data"
      :event-title="data.eventTitle"
      :event-hero-image-url="data.eventHeroImageUrl"
      @close-modal="isShowModal.start = false"
      @open-sign-up-modal="isShowModal.signUp = true"
      @open-auth-modal="isShowModal.auth = true"
    />
    <SignUpModal
      v-if="isShowModal.signUp && data"
      :data="data"
      :all-fields="allFields"
      @close-modal="closeSignUpModal()"
      @open-successful-modal="isShowModal.successful = true"
      @open-pds-error-modal="isShowModal.pdsError = true"
      @open-auth-modal="isShowModal.auth = true"
    />
    <AuthModal
      v-if="isShowModal.auth"
      @close-modal="isShowModal.auth = false"
      @open-recovery-modal="isShowModal.recoveryByEmail = true"
    />
    <SuccessfulModal
      v-if="isShowModal.successful && data"
      :event-title="data.eventTitle"
      :event-hero-image-url="data.eventHeroImageUrl"
      @close-modal="isShowModal.successful = false"
    />
    <PdsErrorModal v-if="isShowModal.pdsError" @close-modal="isShowModal.pdsError = false" />
    <RecoveryByEmailModal
      v-if="isShowModal.recoveryByEmail"
      @close-modal="isShowModal.recoveryByEmail = false"
    />
  </div>
</template>

<script lang="ts">
export default { name: 'EventSignUp' };
</script>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import SignUpModal from '@/components/event/shared/EventSignUp/modals/SignUpModal/SignUpModal.vue';
import PdsErrorModal from '@/components/event/shared/EventSignUp/modals/PdsErrorModal.vue';
import SuccessfulModal from '@/components/event/shared/EventSignUp/modals/SuccessfulModal.vue';
import { TEventSignUpFormResponse } from '@/helpers/types/event/event.types';
import { useApiCall } from '@/helpers/composables/api-call.composable';
import { SignUpApi } from '@/api/apis/SignUp.api';
import { useRoute } from 'vue-router/composables';
import store from '@/store';
import { RouterUtil } from '@/utils/router.util';
import StartModal from '@/components/event/shared/EventSignUp/modals/StartModal.vue';
import { useAuthStore } from '@/store/pinia/auth/auth.store';
import AuthModal from '@/components/event/shared/EventSignUp/modals/AuthModal.vue';
import RecoveryByEmailModal from '@/components/event/shared/EventSignUp/modals/RecoveryByEmailModal.vue';

const route = useRoute();

const authStore = useAuthStore();

const data = ref<TEventSignUpFormResponse | null>(null);
const fetchData = useApiCall(async () => {
  data.value = await SignUpApi.getSignUpForm(route.params.slug);
}, true);

const allFields = computed(() => {
  const steps = data.value?.steps ?? [];
  return steps.flatMap((item, index) => {
    return item.fields;
  });
});

const isShowModal = ref({
  start: false,
  signUp: false,
  auth: false,
  successful: false,
  pdsError: false,
  recoveryByEmail: false,
});

const startSignUp = async () => {
  if (fetchData.isLoading.value) return;
  // Если залогинен, то идем по флоу регистрации
  if (authStore.isLoggedIn) {
    // Если все поля известны, зарегистрировать автоматически (без модалки регистрации)
    if (allFields.value.length) {
      isShowModal.value.signUp = true;
    } else {
      await SignUpApi.signUp(route.params.slug, {});
      await store.dispatch('event/AGetMyEventList');
      isShowModal.value.successful = true;
    }
  } else {
    // Если не залогинен, то начинаем с модалки выбора войти или зарегаться
    isShowModal.value.start = true;
  }
};

const closeSignUpModal = () => {
  isShowModal.value.signUp = false;
  RouterUtil.changeQuery({ appModalRegistration: undefined });
};

onMounted(async () => {
  await fetchData.call(null);

  if (route.query.appModalRegistration === '1') {
    isShowModal.value.signUp = true;
  }
});

watch(
  () => authStore.isLoggedIn,
  async () => {
    await fetchData.call(null);
  }
);
</script>

<style scoped lang="scss"></style>
