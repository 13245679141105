<template>
  <AppModal
    :is-show-modal="true"
    :is-confirm-exit="false"
    :is-hide-icon-close="true"
    inner-width="340px"
  >
    <div class="w-full flex flex-col items-center gap-5">
      <div class="text-[80px]/[80px]">🥺</div>

      <div class="text-lg text-gray text-center">
        К сожалению, не получилось завершить вашу регистрацию. Попробуйте повторить попытку немного
        позже.
      </div>

      <div class="w-full">
        <UIButton type="primary" size="large" @click="$emit('close-modal')"> Продолжить </UIButton>
      </div>
    </div>
  </AppModal>
</template>

<script lang="ts">
// Модалка поверх модалки, отображает ошибку с пдс
export default { name: 'PdsErrorModal' };
</script>

<script setup lang="ts">
import AppModal from '@/ui/components/modals/app-modal';
import UIButton from '@/components/UI/UIButton/UIButton.vue';

defineEmits<{
  (e: 'close-modal'): void;
}>();
</script>

<style scoped lang="scss"></style>
