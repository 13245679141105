<template>
  <AppModal
    :is-show-modal="true"
    @toggleModal="$emit('close-modal')"
    text-head="Восстановление пароля"
  >
    <form v-if="!isLetterSent" @submit.prevent="recover.call(null)">
      <div class="app-modal-recovery-by-email__subtitle">
        <AppText
          type="primary-book"
          color="secondary"
          :text="'Введите E-mail, который вы использовали при регистрации, на него мы отправим подтверждение сброса пароля.'"
        />
      </div>
      <AppInput
        label="E-mail"
        placeholder="example@mail.com"
        :value="email"
        :errors="errors"
        class="app-modal-recovery-by-email__input-email"
        @onInput="onInput($event)"
      />
      <AppButton text="Отправить" web-type="submit" :is-disabled="recover.isLoading.value" />
    </form>

    <div class="app-modal-recovery-by-email__container" v-else>
      <img
        :src="require(`@/assets/images/Auth/sendLetter.svg`)"
        alt="Письмо"
        class="app-modal-recovery-by-email__img"
      />
      <div class="app-modal-recovery-by-email__description">
        <AppText
          type="primary-book"
          color="main"
          text="Если ваша почта зарегистрирована на платформе, вы получите письмо с ссылкой на восстановление пароля. Если вы еще не зарегистрированы, пожалуйста, создайте аккаунт, чтобы начать пользоваться нашим сервисом."
        />
        <AppText
          type="primary-book"
          color="secondary"
          :text="'Нет письма? Проверьте папку «Спам»'"
          class="app-modal-recovery-by-email__spam"
        />
      </div>
    </div>
  </AppModal>
</template>

<script lang="ts">
export default { name: 'RecoveryByEmailModal' };
</script>

<script setup lang="ts">
import AppModal from '@/ui/components/modals/app-modal';
import AppText from '@/ui/components/text/app-text/AppText.vue';
import AppInput from '@/ui/components/inputs/app-input/AppInput.vue';
import AppButton from '@/ui/components/buttons/app-button/AppButton.vue';
import { ref } from 'vue';
import { useApiCall } from '@/helpers/composables/api-call.composable';
import { EX_$Toaster } from '@/classes/content';
import { SignUpApi } from '@/api/apis/SignUp.api';

const emit = defineEmits<{
  (e: 'close-modal'): void;
}>();

const email = ref('');
const errors = ref<string[]>([]);
const isLetterSent = ref(false);

const onInput = (value: string) => {
  email.value = value;
  errors.value = [];
};

const recover = useApiCall(
  async () => {
    await SignUpApi.recover({ email: email.value });
    isLetterSent.value = true;
    EX_$Toaster.success('Письмо выслано на указанный e-mail');
  },
  false,
  (e) => {
    const errorData = e?.response?.data;
    if (errorData && errorData.message === 'validation.error') {
      errors.value = [errorData.errors.email[0]];
    }
  }
);
</script>

<style scoped lang="scss"></style>
