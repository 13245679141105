<template>
  <div class="box-border select-none" v-click-outside="() => (isShownList = false)">
    <UILabel :size="size" :is-disabled="isDisabled" class="mb-1">
      <template #label><slot name="label" /></template>
      <template #label-info><slot name="label-info" /></template>
    </UILabel>

    <div
      class="flex items-center justify-between bg-neutral-100 relative"
      :class="{
        'border-solid border-1 border-red-500': errorMessages?.length,
        'cursor-pointer': !isDisabled,

        'h-[46px] rounded-sm px-4': size === 'medium',
        'h-[56px] rounded-md px-4': size === 'large',
      }"
      @click="toggleList()"
    >
      <div
        class="text-lg/2"
        :class="{
          'text-black-50': selectedOption && !isDisabled,
          'text-gray': !selectedOption || isDisabled,
        }"
      >
        {{ selectedOption ? selectedOption[labelField] : placeholderText }}
      </div>

      <UIIcon
        name="chevron-up"
        :size="24"
        :rotate="isShownList ? '0deg' : '-180deg'"
        transform-style="transition-duration: 0.3s; transition-property: transform"
        class="stroke-black-50 fill-none"
        :class="{ 'opacity-40': isDisabled }"
      />

      <transition :name="positionY === 'top' ? 'slide-down' : 'slide-up'" mode="out-in">
        <div
          v-if="isShownList"
          class="absolute left-[0] right-[0] z-[1001] bg-white rounded-md shadow"
          :class="{
            'pr-[10px] py-[10px]': optionsList?.length >= 4,
            'top-[54px]': size === 'medium',
            'top-[64px]': size === 'large',
          }"
          :style="`${positionY}: calc(-${optionsRef?.offsetHeight + 6}px);`"
          ref="optionsRef"
        >
          <div>
            <ul
              class="flex flex-col gap-2.5 p-2.5 overflow-y-auto max-h-[162px] lg:max-h-[202px] custom-scroll"
            >
              <li
                v-for="item in optionsList"
                :id="item[keyField]"
                :key="item[keyField]"
                class="cursor-pointer flex items-center gap-[15px] p-[8px] ui-select-options-list-item rounded hover:bg-neutral-100"
                :class="{
                  'bg-neutral-100 ': selectedOption?.[keyField] === item[keyField],
                }"
                @click.stop="clickOption(item)"
              >
                <div class="text-sm options-list-item-content">
                  <slot name="option" v-bind:option="item">
                    {{ item[labelField] }}
                  </slot>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </transition>
    </div>

    <UIErrorMessages :error-messages="errorMessages" class="mt-1" />
  </div>
</template>

<script lang="ts">
export default { name: 'UISelect' };
</script>

<script setup lang="ts">
import UIErrorMessages from '@/components/UI/shared/UIErrorMessages.vue';
import { UISize } from '@/helpers/types/ui.types';
import UIIcon from '@/components/UI/UIIcon/UIIcon.vue';
import UILabel from '@/components/UI/shared/UILabel.vue';
import { ref } from 'vue';

interface Option {
  [key: string]: any;
}

const emits = defineEmits<{
  (e: 'select-option', option: any): void;
}>();

const props = withDefaults(
  defineProps<{
    size: UISize;
    selectedOption: any;
    optionsList: Option[];
    keyField?: string;
    labelField?: string;
    placeholderText?: string;
    errorMessages?: string[];
    isDisabled?: boolean;
    positionY?: 'top' | 'bottom';
  }>(),
  {
    positionY: 'bottom',
  }
);

const isShownList = ref(false);
const toggleList = () => {
  if (props.isDisabled) return;

  isShownList.value = !isShownList.value;
  // При открытии списка проматываем его до выбранного элемента
  if (isShownList.value && props.selectedOption) {
    setTimeout(() => {
      if (props.selectedOption && props.keyField !== undefined) {
        document.getElementById(props.selectedOption[props.keyField])?.scrollIntoView({
          block: 'nearest',
        });
      }
    }, 0);
  }
};

const optionsRef = ref<HTMLElement | null>(null);
const clickOption = (item: Option) => {
  emits('select-option', item);
  isShownList.value = false;
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/shared/slide-transition';

.custom-scroll {
  width: 100%;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 7px;

    &-thumb {
      width: 7px;
      border-radius: 3.5px;
      background-color: rgba(131, 136, 143, 0.5);
      background-clip: content-box;
    }

    &-track {
      background: none;
      border-radius: 3.5px;
    }
  }
}
</style>
