var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => (_setup.isShownList = false)),expression:"() => (isShownList = false)"}],staticClass:"box-border select-none"},[_c(_setup.UILabel,{staticClass:"mb-1",attrs:{"size":_vm.size,"is-disabled":_vm.isDisabled},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("label")]},proxy:true},{key:"label-info",fn:function(){return [_vm._t("label-info")]},proxy:true}],null,true)}),_c('div',{staticClass:"flex items-center justify-between bg-neutral-100 relative",class:{
      'border-solid border-1 border-red-500': _vm.errorMessages?.length,
      'cursor-pointer': !_vm.isDisabled,

      'h-[46px] rounded-sm px-4': _vm.size === 'medium',
      'h-[56px] rounded-md px-4': _vm.size === 'large',
    },on:{"click":function($event){return _setup.toggleList()}}},[_c('div',{staticClass:"text-lg/2",class:{
        'text-black-50': _vm.selectedOption && !_vm.isDisabled,
        'text-gray': !_vm.selectedOption || _vm.isDisabled,
      }},[_vm._v(" "+_vm._s(_vm.selectedOption ? _vm.selectedOption[_vm.labelField] : _vm.placeholderText)+" ")]),_c(_setup.UIIcon,{staticClass:"stroke-black-50 fill-none",class:{ 'opacity-40': _vm.isDisabled },attrs:{"name":"chevron-up","size":24,"rotate":_setup.isShownList ? '0deg' : '-180deg',"transform-style":"transition-duration: 0.3s; transition-property: transform"}}),_c('transition',{attrs:{"name":_vm.positionY === 'top' ? 'slide-down' : 'slide-up',"mode":"out-in"}},[(_setup.isShownList)?_c('div',{ref:"optionsRef",staticClass:"absolute left-[0] right-[0] z-[1001] bg-white rounded-md shadow",class:{
          'pr-[10px] py-[10px]': _vm.optionsList?.length >= 4,
          'top-[54px]': _vm.size === 'medium',
          'top-[64px]': _vm.size === 'large',
        },style:(`${_vm.positionY}: calc(-${_setup.optionsRef?.offsetHeight + 6}px);`)},[_c('div',[_c('ul',{staticClass:"flex flex-col gap-2.5 p-2.5 overflow-y-auto max-h-[162px] lg:max-h-[202px] custom-scroll"},_vm._l((_vm.optionsList),function(item){return _c('li',{key:item[_vm.keyField],staticClass:"cursor-pointer flex items-center gap-[15px] p-[8px] ui-select-options-list-item rounded hover:bg-neutral-100",class:{
                'bg-neutral-100 ': _vm.selectedOption?.[_vm.keyField] === item[_vm.keyField],
              },attrs:{"id":item[_vm.keyField]},on:{"click":function($event){$event.stopPropagation();return _setup.clickOption(item)}}},[_c('div',{staticClass:"text-sm options-list-item-content"},[_vm._t("option",function(){return [_vm._v(" "+_vm._s(item[_vm.labelField])+" ")]},{"option":item})],2)])}),0)])]):_vm._e()])],1),_c(_setup.UIErrorMessages,{staticClass:"mt-1",attrs:{"error-messages":_vm.errorMessages}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }