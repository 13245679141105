<template>
  <UIInput
    :placeholder="placeholder ?? ''"
    :max-length="maxLength"
    :is-max-length-visible="false"
    :size="size"
    :value="inputFormatted"
    :error-messages="invalidPhoneErrorMessages"
    @input-raw="onInputRaw($event)"
    @paste="onPaste"
    @keydown="onKeydown"
  >
    <template #label><slot name="label" /></template>
    <template #label-info><slot name="label-info" /></template>

    <template #leftPlace>
      <CountryDropdown
        :selected-country="country"
        :is-visible="popupIsVisible"
        :countries="countriesList"
        @select-country="handleSelectCountry"
      />
    </template>
  </UIInput>
</template>

<script lang="ts">
export default { name: 'UIPhoneInput' };
</script>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { AsYouType, isValidPhoneNumber, parsePhoneNumberWithError } from 'libphonenumber-js/max';
import { ICountryNumber } from '@/services/AuthService/AuthServiceTypes';
import { UISize } from '@/helpers/types/ui.types';
import { CountryCode } from 'libphonenumber-js/types';
import countriesData from '@/assets/all-countries.json';
import UIInput from '@/components/UI/UIInput/UIInput.vue';
import CountryDropdown from '@/components/event/country-dropdown/CountryDropdown.vue';

const props = defineProps<{
  value?: string;
  size: UISize;
  placeholder?: string;
  errorMessages?: string[];
}>();

const emit = defineEmits(['input']);

const inputFormatted = ref('');
const country = ref<CountryCode>('RU');
const popupIsVisible = ref(false);
const countriesList = ref(countriesData);

const onPaste = (e: ClipboardEvent) => {
  const inputPaste = e.clipboardData?.getData('text/plain');
  const extractedNumbers = inputPaste?.replace(/[^0-9]/g, '');

  if (!extractedNumbers) {
    return;
  }

  try {
    const preparedPhoneNumber = preformatInputValue(extractedNumbers);
    const foundPhoneNumber = parsePhoneNumberWithError(preparedPhoneNumber);
    const number = foundPhoneNumber.number;
    inputFormatted.value = formatInput(number);
  } catch (error) {
    inputFormatted.value = extractedNumbers;
  }

  emitInput();
};

const onKeydown = (e: KeyboardEvent): void => {
  const inputChar = e.key;

  // Если это комбинация клавиш для вставки
  if ((e.metaKey || e.ctrlKey) && inputChar.toLowerCase() === 'v') {
    // Откладываем обработку вставки с использованием setTimeout
    setTimeout(() => {
      const targetInput = e.target as HTMLInputElement;
      onInput(targetInput.value);
    }, 0);
  } else {
    // Разрешаем только цифры и backspace
    if (!(inputChar.match(/^\d+$/) || inputChar === 'Backspace' || inputChar === 'Tab')) {
      e.preventDefault();
    }
  }
};
const onInputRaw = (event: InputEvent) => {
  const target = event.target as HTMLInputElement;
  let value = target.value;
  if (!value) {
    inputFormatted.value = '';
    target.value = inputFormatted.value;
    emitInput();
  } else {
    value = preformatInputValue(value);
    value = formatInput(value);
    inputFormatted.value = value;
    target.value = inputFormatted.value;
    emitInput();
  }
};
const onInput = (value: string) => {
  if (!value) {
    inputFormatted.value = '';
    emitInput();
  } else {
    value = preformatInputValue(value);
    value = formatInput(value);
    inputFormatted.value = value;
    emitInput();
  }
};

const emitInput = () => {
  const isValid = isValidPhoneNumber(inputFormatted.value);
  try {
    const foundPhoneNumber = parsePhoneNumberWithError(inputFormatted.value);

    if (foundPhoneNumber.number && foundPhoneNumber.country) {
      const number = foundPhoneNumber.number;
      country.value = foundPhoneNumber.country;

      emit('input', { value: number, isValid });
    }
  } catch (error) {
    emit('input', { value: inputFormatted.value, isValid });
  }
};

const formatInput = (value: string) => {
  const asYouType = new AsYouType(country.value);
  return asYouType.input(value);
};

const preformatInputValue = (value: string): any => {
  if (value.startsWith('8') && country.value === 'RU') {
    return preformatInputValue(`+7${value.substring(1)}`);
  }
  if (value.startsWith('+8') && country.value === 'RU') {
    return preformatInputValue(`+7${value.substring(2)}`);
  }
  if (!value.startsWith('+')) {
    return preformatInputValue(`+${value}`);
  }
  return value;
};

const handleSelectCountry = (value: ICountryNumber) => {
  const isValid = isValidPhoneNumber(inputFormatted.value);
  country.value = value.code;
  inputFormatted.value = value.dial_code;
  emit('input', { value: value.dial_code, isValid });
};

const isFullNumber = computed((): boolean => {
  const phoneWithOneAdditionalChar = inputFormatted.value + '0';
  if (isValidPhoneNumber(phoneWithOneAdditionalChar)) return false;
  return isValidPhoneNumber(inputFormatted.value);
});

const maxLength = computed((): number => {
  return isFullNumber.value ? inputFormatted.value.length : 17;
});

const invalidPhoneErrorMessages = computed((): string[] => {
  const isValid = isValidPhoneNumber(inputFormatted.value);
  const errorsLength = props.errorMessages?.length;

  if (!country.value && errorsLength && !isValid) {
    return ['Выберите код страны и введите номер телефон'];
  }
  if (!country.value) {
    return ['Выберите код страны'];
  }
  if (errorsLength && !isValid) {
    return props.errorMessages;
  }
  return [];
});

watch(
  () => props.value,
  (newValue) => {
    if (newValue) {
      inputFormatted.value = formatInput(newValue);
    }
    if (!newValue) {
      inputFormatted.value = '';
    }
  },
  { immediate: true }
);

// onMounted(() => {
//   inputFormatted.value = '+7';
//   const isValid = isValidPhoneNumber(inputFormatted.value);
//   emit('input', { value: '+7', isValid });
// });
</script>

<style scoped></style>
