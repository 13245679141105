export function getBackgroundImage(
  imageUrl: string | undefined | null,
  format: 'default' | 'object' = 'default',
  backgroundColor?: string
) {
  const urlString = imageUrl ? `url(${imageUrl}) center center / cover no-repeat` : '';
  const colorString = backgroundColor ?? '';

  switch (format) {
    case 'default':
      return `background: ${colorString} ${urlString};`;
    case 'object':
      return { background: `${colorString} ${urlString}` };
  }
}
