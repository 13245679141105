<template>
  <AppModal :is-show-modal="true" @toggleModal="$emit('close-modal')">
    <div v-if="currentStepData" class="sign-up-modal">
      <div class="text-2xl font-semibold mt-1 mb-5 text-center">{{ currentStepData.title }}</div>
      <!--      Отображаем предложение войти, если юзер не авторизован-->
      <div v-if="!authStore.isLoggedIn" class="app-modal-registration__subtitle">
        <AppText type="primary-book" color="secondary" :text="'Уже есть аккаунт?'" />
        <div @click="openAuthModal()" class="app-modal-auth__text">
          <AppText type="primary-book" color="text-link" :text="'Войдите'" />
        </div>
      </div>

      <form
        v-if="currentStepFormFields?.length"
        class="flex flex-col"
        @submit.prevent="submit.call(null)"
      >
        <div v-for="field in currentStepFormFields" :key="field.key" :id="field.key">
          <div
            v-if="['special.mobilePhoneNumber'].includes(field.platformType)"
            class="w-full mb-3"
          >
            <UIPhoneInput
              class="relative z-50"
              size="medium"
              :value="form[field.key]"
              :error-messages="errors[field.key] && errors[field.key].flat()"
              @input="setValue(field, $event.value)"
            >
              <template #label>
                {{ getFieldLabel(field.name, field.required) }}
              </template>
            </UIPhoneInput>

            <SignUpModalNote :note="field.additionalOptions?.note" />
          </div>

          <div v-else-if="['email'].includes(field.platformType)" class="w-full mb-3">
            <UIInput
              :value="form[field.key]"
              size="medium"
              :placeholder="field.additionalOptions?.placeholder"
              inputmode="email"
              :error-messages="errors[field.key]"
              @input="setValue(field, $event)"
              @blur="checkIsEmailExist(field.platformType)"
            >
              <template #label>{{ getFieldLabel(field.name, field.required) }}</template>
            </UIInput>

            <SignUpModalNote :note="field.additionalOptions?.note" />
          </div>

          <div v-else-if="['password'].includes(field.platformType)" class="w-full mb-3">
            <UIInput
              :value="form[field.key]"
              size="medium"
              :placeholder="field.additionalOptions?.placeholder"
              :error-messages="errors[field.key]"
              :native-type="checkIsPasswordShown(field.key) ? 'text' : 'password'"
              autocomplete="new-password"
              @input="setValue(field, $event)"
            >
              <template #label>{{ getFieldLabel(field.name, field.required) }}</template>

              <template #rightPlace>
                <div
                  class="app-modal-registration__password-controller"
                  @click="togglePasswordShown(field.key)"
                >
                  <AppIcon :name="checkIsPasswordShown(field.key) ? 'eye' : 'eye-off'" />
                </div>
              </template>
            </UIInput>

            <SignUpModalNote :note="field.additionalOptions?.note" />
          </div>

          <div
            v-else-if="
              ['input.string', 'special.ogrn', 'special.passport', 'special.snils'].includes(
                field.platformType
              )
            "
            class="w-full mb-3"
          >
            <UIInput
              :value="form[field.key]"
              size="medium"
              :placeholder="field.additionalOptions?.placeholder"
              :error-messages="errors[field.key]"
              @input="setValue(field, $event)"
            >
              <template #label>{{ getFieldLabel(field.name, field.required) }}</template>
            </UIInput>

            <SignUpModalNote :note="field.additionalOptions?.note" />
          </div>

          <div v-else-if="['input.date'].includes(field.platformType)" class="w-full mb-3">
            <UIInput
              :value="form[field.key]"
              v-mask="'##.##.####'"
              size="medium"
              :placeholder="field.additionalOptions?.placeholder"
              :error-messages="errors[field.key]"
              @input-raw="changeDate(field, $event)"
            >
              <template #label>{{ getFieldLabel(field.name, field.required) }}</template>
            </UIInput>

            <SignUpModalNote :note="field.additionalOptions?.note" />
          </div>

          <div
            v-else-if="
              field.platformType.includes('location') && _.isEmpty(field.additionalOptions?.default)
            "
            class="w-full mb-3"
          >
            <AppInputDropdown
              list-key="title"
              :label="getFieldLabel(field.name, field.required)"
              :placeholder="field.additionalOptions?.placeholder ?? ''"
              :is-loading="Boolean(abortController[field.key])"
              :default-value="form[field.key]"
              :list="fieldData[field.key] ?? []"
              :errors="errors[field.key]"
              :disabled="!checkIsAllDependsOnFieldsFilled(field)"
              :is-have-empty-block="false"
              @search="debouncedFetchFieldData(field, $event)"
              @onSelect="setValue(field, $event)"
            />

            <SignUpModalNote :note="field.additionalOptions?.note" />
          </div>

          <div v-else-if="field.platformType === 'select.one'" class="w-full mb-3">
            <UISelect
              :selected-option="form[field.key] ? { name: form[field.key] } : undefined"
              :options-list="adaptOptions(field.additionalOptions?.selectValues)"
              size="medium"
              :placeholder-text="field.additionalOptions?.placeholder"
              key-field="name"
              label-field="name"
              :error-messages="errors[field.key]"
              @select-option="setValue(field, $event.name)"
            >
              <template #label>{{ getFieldLabel(field.name, field.required) }}</template>
            </UISelect>

            <SignUpModalNote :note="field.additionalOptions?.note" />
          </div>

          <div
            v-else-if="field.platformType === 'select.multiple'"
            class="registration-multiselect w-full mb-3"
          >
            <div class="registration-multiselect-label">
              {{ getFieldLabel(field.name, field.required) }}
            </div>
            <UIMultiselect
              :selected-list="form[field.key]?.map((listItem) => ({ name: listItem })) ?? []"
              :options-list="adaptOptions(field.additionalOptions?.selectValues)"
              key-field="name"
              label-field="name"
              :error-messages="errors[field.key]"
              @onSelect="
                setValue(
                  field,
                  $event.map((item) => item.name)
                )
              "
            />

            <SignUpModalNote :note="field.additionalOptions?.note" />
          </div>

          <div v-else-if="field.platformType === 'checkbox'" class="w-full mb-3">
            <AppCheckBox
              type="checkbox"
              :text="getFieldLabel(field.name, field.required)"
              :is-checked="form[field.key]"
              class="mt-2"
              @change="setValue(field, !form[field.key])"
            />
            <UIErrorMessages :error-messages="errors[field.key]" class="text-xs" />

            <SignUpModalNote :note="field.additionalOptions?.note" />
          </div>
        </div>

        <UIButton
          :key="stepIndex"
          type="primary"
          size="medium"
          submit
          :disabled="submit.isLoading.value"
        >
          {{ currentStepData.buttonText }}
        </UIButton>

        <UIButton
          v-if="stepIndex !== 0"
          type="primary"
          size="medium"
          ghost
          class="mt-3"
          @click="goToPreviousStep()"
        >
          К предыдущему шагу
        </UIButton>
      </form>
    </div>

    <div v-else class="spinner-wrapper"><UILoader /></div>
  </AppModal>
</template>

<script lang="ts">
export default { name: 'SignUpModal' };
</script>

<script setup lang="ts">
import AppModal from '@/ui/components/modals/app-modal';
import {
  TEventSignUpFormResponse,
  TEventSignUpFormStepField,
} from '@/helpers/types/event/event.types';
import Vue, { computed, onMounted, ref } from 'vue';
import UILoader from '@/components/UI/UILoader.vue';
import AppText from '@/ui/components/text/app-text/AppText.vue';
import { useAuthStore } from '@/store/pinia/auth/auth.store';
import AppCheckBox from '@/ui/components/switchers/app-check-box/AppCheckBox.vue';
import UIMultiselect from '@/components/UI/UIMultiselect.vue';
import UIErrorMessages from '@/components/UI/shared/UIErrorMessages.vue';
import { AppIcon } from '@/ui/components';
import AppInputDropdown from '@/ui/components/inputs/app-input-dropdown/AppInputDropdown.vue';
import { moveItemsByCondition } from '@/helpers/methods/array.methods';
import { SignUpApi } from '@/api/apis/SignUp.api';
import UIInput from '@/components/UI/UIInput/UIInput.vue';
import UISelect from '@/components/UI/UISelect/UISelect.vue';
import _ from 'lodash';
import UIButton from '@/components/UI/UIButton/UIButton.vue';
import { useRoute } from 'vue-router/composables';
import { useApiCall } from '@/helpers/composables/api-call.composable';
import { JwtTokensHelper } from '@/helpers/classes/jwt-tokens-helper.class';
import store from '@/store';
import { EX_$Toaster } from '@/classes/content';
import { YandexMetrikaUtil } from '@/utils/yandex-metrika.util';
import { StatisticsApi } from '@/api/apis/StatisticsApi';
import SignUpModalNote from '@/components/event/shared/EventSignUp/modals/SignUpModal/SignUpModalNote.vue';
import UIPhoneInput from '@/components/UI/UIInput/UIPhoneInput.vue';

const props = defineProps<{
  data: TEventSignUpFormResponse;
  allFields: TEventSignUpFormStepField[];
}>();

const emit = defineEmits<{
  (e: 'close-modal'): void;
  (e: 'open-successful-modal'): void;
  (e: 'open-pds-error-modal'): void;
  (e: 'open-auth-modal'): void;
}>();

const route = useRoute();

const authStore = useAuthStore();

const stepIndex = ref(0);

const currentStepData = computed(() => {
  return props.data.steps[stepIndex.value];
});
const currentStepFormFields = computed(() => {
  if (!currentStepData.value?.fields) return;

  // Костыль чтобы поле "Хочу получать уведомления" было всегда в конце (на беке сложно сделать, потому что там 4 метода для возврата полей)
  return moveItemsByCondition(
    currentStepData.value.fields,
    (item) => {
      return (
        item.key === 'wantReceiveNotificationsAndNews' || item.key === 'personalDataProcessing'
      );
    },
    'end'
  );
});
const isLastStep = computed(() => {
  return props.data.steps.length - 1 === stepIndex.value;
});

const goToPreviousStep = () => {
  stepIndex.value -= 1;
  errors.value = {};
  document.getElementById('appModalTop')?.scrollIntoView();
};

const form = ref<Record<string, any>>({});
const setValue = (field: TEventSignUpFormStepField, value: any): void => {
  Vue.set(form.value, field.key, value);
  setFieldError(field.key, undefined);

  const fieldsThatDependOn = props.allFields.filter((item) =>
    item.additionalOptions?.dependsOn?.includes(field.key)
  );
  fieldsThatDependOn?.forEach((item) => {
    setValue(item, undefined);
  });
};

const errors = ref<Record<string, any>>({});
const setFieldError = (key: string, value: any): void => {
  Vue.set(errors.value, key, value);
};

const abortController = ref<Record<string, AbortController | undefined>>({});
const setAbortController = (key: string, value: AbortController | undefined): void => {
  Vue.set(abortController.value, key, value);
};

const fieldData = ref<Record<string, any>>({});
const setFieldData = (key: string, value: any): void => {
  Vue.set(fieldData.value, key, value);
};

const openAuthModal = () => {
  emit('close-modal');
  emit('open-auth-modal');
};

const checkIsEmailExist = async (key: string): Promise<void> => {
  if (!form.value[key]) return;

  try {
    await SignUpApi.checkIsEmailExist(form.value[key]);
  } catch (e: any) {
    setFieldError(key, e.response.data.errors[key]);
  }
};

const getFieldLabel = (name: string, isRequired: boolean): string => {
  return `${name}${isRequired ? ' *' : ''}`;
};

const fetchFieldData = async (field: TEventSignUpFormStepField, search: string): Promise<void> => {
  const currentAbortController = abortController.value[field.key];
  if (currentAbortController) currentAbortController.abort();

  const newAbortController = new AbortController();

  try {
    setAbortController(field.key, newAbortController);

    switch (field.platformType) {
      case 'location.country':
        {
          if (search.length > 2) {
            const res = await SignUpApi.getCountryOptions(search, newAbortController.signal);
            setFieldData(field.key, res);
          }
        }
        break;
      case 'location.region':
        {
          if (search.length > 2) {
            const dependsOn = field.additionalOptions?.dependsOn;
            const dependsOnFields = props.allFields.filter((item) => dependsOn?.includes(item.key));
            const countryField = dependsOnFields?.find(
              (item) => item.platformType === 'location.country'
            );
            // TODO отрефачить и добавить ошибку
            if (!countryField) return;
            const res = await SignUpApi.getRegionOptions(
              { search, countryId: form.value?.[countryField.key]?.id },
              newAbortController.signal
            );
            setFieldData(field.key, res);
          }
        }
        break;
      case 'location.city':
        {
          if (search.length > 2) {
            const dependsOn = field.additionalOptions?.dependsOn;
            const dependsOnFields = props.allFields.filter((item) => dependsOn?.includes(item.key));
            const countryField = dependsOnFields?.find(
              (item) => item.platformType === 'location.country'
            );
            // TODO отрефачить и добавить ошибку
            if (!countryField) return;

            const regionField = dependsOnFields?.find(
              (item) => item.platformType === 'location.region'
            );
            // TODO отрефачить и добавить ошибку
            if (!regionField) return;
            fieldData.value[field.key] = await SignUpApi.getCityOptions(
              {
                search,
                countryId: form.value?.[countryField.key]?.id,
                regionId: form.value?.[regionField.key]?.id,
                regionName: form.value?.[regionField.key]?.title,
              },
              newAbortController.signal
            );
          }
        }
        break;
      case 'location.municipality':
        {
          if (search.length > 2) {
            const dependsOn = field.additionalOptions?.dependsOn;
            const dependsOnFields = props.allFields.filter((item) => dependsOn?.includes(item.key));
            const countryField = dependsOnFields?.find(
              (item) => item.platformType === 'location.country'
            );
            // TODO отрефачить и добавить ошибку
            if (!countryField) return;

            const regionField = dependsOnFields?.find(
              (item) => item.platformType === 'location.region'
            );
            // TODO отрефачить и добавить ошибку
            if (!regionField) return;

            const cityField = dependsOnFields?.find(
              (item) => item.platformType === 'location.city'
            );
            // TODO отрефачить и добавить ошибку
            if (!cityField) return;
            const res = await SignUpApi.getMunicipalityOptions(
              {
                search,
                countryId: form.value?.[countryField.key]?.id,
                regionId: form.value?.[regionField.key]?.id,
                regionName: form.value?.[regionField.key]?.title,
                cityId: form.value?.[cityField.key]?.id,
              },
              newAbortController.signal
            );
            fieldData.value[field.key] = res;

            if (!res?.length) {
              setValue(field, { id: 1, title: search });
            }
          }
        }
        break;
    }
  } finally {
    setAbortController(field.key, undefined);
  }
};
const debouncedFetchFieldData = _.debounce(fetchFieldData, 500);

const checkIsAllDependsOnFieldsFilled = (field: TEventSignUpFormStepField): boolean => {
  if (field.additionalOptions?.dependsOn === undefined) return true;
  return Boolean(
    field.additionalOptions.dependsOn.every((dependsOnFieldKey) => {
      return !_.isEmpty(form.value[dependsOnFieldKey]);
    })
  );
};

const adaptOptions = (options: any) => {
  return (
    options?.map((item: any) => {
      return { name: item };
    }) ?? []
  );
};

const isPasswordShown = ref<string[]>([]);
const checkIsPasswordShown = (key: string) => {
  return isPasswordShown.value.includes(key);
};
const togglePasswordShown = (key: string) => {
  if (checkIsPasswordShown(key)) {
    isPasswordShown.value = isPasswordShown.value.filter((item) => item !== key);
  } else {
    isPasswordShown.value.push(key);
  }
};

const changeDate = (field: TEventSignUpFormStepField, event: InputEvent) => {
  const target = event.target as HTMLInputElement;
  const value = target.value;
  const parts = value.split('.');
  if (parts.length >= 2) {
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    if (day > 31) parts[0] = '31';
    if (month > 12) parts[1] = '12';

    const maxYear = new Date().getFullYear();
    if (year > maxYear) parts[2] = String(maxYear);
  }

  const newValue = parts.join('.');
  setValue(field, newValue);
  target.value = newValue;
};

const adaptFormBeforeSend = () => {
  const result = _.cloneDeep(form.value);
  for (const key in result) {
    switch (key) {
      case 'countryOfResidence':
      case 'regionOfResidence':
      case 'cityOfResidence':
      case 'municipalityOfResidence':
        {
          const temp = result[key];
          if (temp) result[key] = { id: temp.id, name: temp.title || temp.name };
        }
        break;
    }
  }
  return result;
};

const submit = useApiCall(
  async () => {
    const adaptedForm = adaptFormBeforeSend();
    await SignUpApi.validateStep(route.params.slug, stepIndex.value, adaptedForm);

    if (!isLastStep.value) {
      stepIndex.value += 1;
      document.getElementById('appModalTop')?.scrollIntoView();
    } else {
      const res = await SignUpApi.signUp(route.params.slug, adaptedForm);
      if (!authStore.isLoggedIn) {
        JwtTokensHelper.setAccessToken(res.accessToken);
        JwtTokensHelper.setRoleInfo(res.accessToken);
        JwtTokensHelper.setRefreshToken(res.refreshToken);
        await store.dispatch('auth/ASetIsLogin', true);
        useAuthStore().setIsLoggedIn(true);
        EX_$Toaster.success('Успешная регистрация');

        // TODO убрать ям
        if (route.name === 'EventPage') YandexMetrikaUtil.reachGoalVisitEventPage();
        if (route.name === 'EventPage') StatisticsApi.visitEventPage();
      }
      emit('open-successful-modal');
      await store.dispatch('event/AGetMyEventList');
    }
  },
  false,
  (e) => {
    const errorData = e.response.data;

    switch (errorData.message) {
      case 'event.sign_up.some_fields_missing':
        {
          //находим поля, обязательные для заполнения
          errorData.missingFields.forEach((key: string) => {
            setFieldError(key, ['Заполните поле']);
          });
        }
        break;

      case 'validation.error':
        {
          //находим поля, непрошедшие валидацию
          for (let key in errorData.errors) {
            setFieldError(key, errorData.errors[key]);
          }
        }
        break;

      case 'auth.user_already_exist':
        {
          setFieldError('email', [
            'Пользователь с таким e-mail уже существует. Для регистрации на событие вам необходимо Войти',
          ]);
        }
        break;

      case 'pds.failed_to_create_or_update_user_info': {
        emit('open-pds-error-modal');
        return;
      }
    }

    scrollToFirstError();
  }
);

const scrollToFirstError = async () => {
  // Оставляем только те ошибки, у которых есть значения
  const errorArray = getErrorsEntries();

  if (!errorArray.length) return;

  const errorKeys = errorArray.map((item) => item[0]);

  // Находим минимальный шаг с ошибкой
  // Изначально это будет последний шаг
  let minimalStepIndexWithFirstError = props.data.steps.length - 1;
  props.data.steps.forEach((step: any, index: number) => {
    const foundField = step.fields.find((field: any) => errorKeys.includes(field.key));
    if (foundField) {
      // Устанавливаем тот шаг, который меньше
      minimalStepIndexWithFirstError = Math.min(minimalStepIndexWithFirstError, index);
    }
  });

  // Берем шаг на котором ошибки
  const minimalStepFields = props.data.steps[minimalStepIndexWithFirstError]?.fields;
  if (!minimalStepFields?.length) return;

  const fieldsWithError = minimalStepFields.filter((field: any) => errorKeys.includes(field.key));
  // Получаем ключ самого первого поля с ошибкой
  const key = fieldsWithError[0]?.key;
  if (!key) return;

  // Если ошибка не на текущем шаге, переключаемся на этот шаг
  if (minimalStepIndexWithFirstError !== stepIndex.value) {
    stepIndex.value = minimalStepIndexWithFirstError;
  }

  // Скролимся к полю с ошибкой
  document.getElementById(key)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
};
const getErrorsEntries = (): [string, string[]][] => {
  // Оставляем только те ошибки, у которых есть значения
  return Object.entries(errors.value).filter((item) => item[1]) as [string, string[]][];
};

onMounted(() => {
  // Предустанавливаем значения по умолчанию в форму
  props.allFields.forEach((field) => {
    if (field.additionalOptions && !_.isEmpty(field.additionalOptions.default)) {
      setValue(field, field.additionalOptions.default);
    }
  });
});
</script>

<style lang="scss">
//.fixed-app-select {
//  .selected-block {
//    height: 46px !important;
//  }
//}
.sign-up-modal {
  .app-input__label {
    margin-bottom: 4px;
    .app-text {
      font-size: 14px;
    }
  }
  .app-input__inner {
    padding: 8.5px 10px;
  }
}
</style>

<style scoped lang="scss">
.app-modal-registration {
  &__subtitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
    .app-text {
      @media (max-width: 768px) {
        font-size: 14px !important;
        line-height: 20px;
      }
    }
  }
  &__text {
    margin-left: 4px;
    cursor: pointer;
  }
  &__input-email {
    margin-top: 2px;
  }
  &__input-password {
    margin-top: 2px;
    margin-bottom: 16px;
  }
  &__forgot-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 16px;
    .app-text {
      font-size: 14px !important;
      line-height: 20px;
    }
  }
  &__password-controller {
    cursor: pointer;
  }
  &__controls {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &__container-names {
    display: flex;
    margin-top: 16px;
    @media (max-width: 440px) {
      flex-direction: column;
    }
    .app-input:not(:last-child) {
      margin-right: 16px;
    }
  }
  &__switcher {
    margin-top: 16px;
    margin-bottom: 24px;
  }
  &__back-button {
    margin-top: 16px;
  }
}

.spinner-wrapper {
  height: 500px;
  width: 100%;
  position: relative;
}
.registration-multiselect {
  margin-bottom: 17px;
  &-label {
    color: var(--main-text-color);
    margin-bottom: 5px;
  }
  .selected-empty {
    height: 40px;
  }
  .selected-empty-text {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .registration-multiselect {
    &-label {
      font-size: 14px;
    }
    .selected-empty {
      height: 32px;
    }
    .selected-empty-text {
      font-size: 16px;
    }
  }
}
</style>
