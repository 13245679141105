<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    text-head="Авторизация"
  >
    <div v-if="isEventPage && false" class="app-modal-auth__subtitle">
      <app-text type="primary-book" color="secondary" :text="'Нет аккаунта?'" />

      <EventSignUp>
        <template #control="{ startSignUp }">
          <div class="app-modal-auth__text" @click="startSignUp()">
            <app-text type="primary-book" color="text-link" :text="'Зарегистрируйтесь'" />
          </div>
        </template>
      </EventSignUp>
    </div>
    <form @submit.prevent="login">
      <AppSocialNetworks />
      <AppInput
        class="app-modal-auth__input-email"
        label="E-mail"
        placeholder="example@mail.com"
        v-model="inputValueEmail"
        :errors="errorTextEmail"
        name="username"
        @onInput="(val) => onInput(val, 'email')"
      />
      <AppInput
        class="app-modal-auth__input-password"
        label="Пароль"
        placeholder="Введите пароль"
        v-model="inputValuePassword"
        :type="isPassHidden ? 'password' : 'text'"
        :errors="errorTextPassword"
        name="password"
        @onInput="(val) => onInput(val, 'password')"
      >
        <template #append>
          <div class="app-modal-auth__password-controller" @click="isPassHidden = !isPassHidden">
            <app-icon :name="isPassHidden ? 'eye-off' : 'eye'" />
          </div>
        </template>
      </AppInput>
      <AppButton text="Продолжить" web-type="submit" :is-disabled="getIsDisabledButton" />
    </form>
    <div class="app-modal-auth__forgot-container">
      <app-text type="primary-book" color="secondary" :text="'Забыли пароль?'" />
      <div @click="openRecoveryModal" class="app-modal-auth__text">
        <app-text type="primary-book" color="text-link" :text="'Восстановите здесь'" />
      </div>
    </div>
  </AppModal>
</template>

<script lang="ts">
import Vue from 'vue';

import AppText from '@/ui/components/text/app-text/AppText.vue';
import AppModal from '@/ui/components/modals/app-modal';
import AppInput from '@/ui/components/inputs/app-input/AppInput.vue';
import AppButton from '@/ui/components/buttons/app-button/AppButton.vue';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';

import { AppSocialNetworks } from '@/components/modals';
import { MAuth } from '@/mixins';
import { mapActions } from 'vuex';
import { EX_$Modal } from '@/classes/content';
import EventSignUp from '@/components/event/shared/EventSignUp/EventSignUp.vue';

export default Vue.extend({
  name: 'AppModalAuth',
  components: { EventSignUp, AppButton, AppInput, AppText, AppModal, AppSocialNetworks, AppIcon },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [MAuth],
  data() {
    return {
      EX_$ModalClass: EX_$Modal,
      email: '',
      password: '',
      isPassHidden: true,
    };
  },
  methods: {
    ...mapActions('auth', ['AAuth']),
    toggleModal() {
      //@ts-ignore
      this.clearInputData();
      //@ts-ignore
      this.clearTextErrors();
      this.$emit('toggleModal');
    },
    openRecoveryModal() {
      this.toggleModal();
      this.EX_$ModalClass.toggleModal('appModalRecoveryByEmail');
    },
    onInput(val: string, field: string) {
      field === 'email'
        ? //@ts-ignore
          (this.inputValueEmail = val)
        : //@ts-ignore
          (this.inputValuePassword = val);
    },
  },
  computed: {
    isEventPage() {
      return this.$route.fullPath.includes('/event/');
    },
  },
});
</script>

<style lang="scss">
@use 'style';
</style>
