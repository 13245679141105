import { IError } from '@/common/types/errors';
import { ICity, ICountry, IRegion } from '@/services/AuthService/AuthServiceTypes';
import { TEventSignUpFormResponse } from '@/helpers/types/event/event.types';

export interface IState {
  isAuthFetching: boolean;
  isLogin: boolean;
  authErrors: IError;
  registrationForm: TEventSignUpFormResponse;
  isFieldsExist: boolean;
  isRefreshing: boolean;
  recoverToken: string;
  searchCities: Array<ICity>;
  isCitiesLoading: boolean;
  searchRegions: Array<IRegion>;
  isRegionsLoading: boolean;
  searchCountries: Array<ICountry>;
  isCountriesLoading: boolean;
  searchMunicipalities: Array<string>;
  isMunicipalitiesLoading: boolean;
  listRoles: Array<string>;
}
export default (): IState => ({
  isAuthFetching: false,
  isLogin: false,
  authErrors: {},
  registrationForm: {} as TEventSignUpFormResponse,
  isFieldsExist: false,
  isRefreshing: false,
  recoverToken: '',
  searchCities: [],
  isCitiesLoading: false,
  searchRegions: [],
  isRegionsLoading: false,
  searchCountries: [],
  isCountriesLoading: false,
  searchMunicipalities: [],
  isMunicipalitiesLoading: false,
  listRoles: [],
});
