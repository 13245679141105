import { IState } from './state';
import { ICity, ICountry, IRegion } from '@/services/AuthService/AuthServiceTypes';
import { TEventSignUpFormResponse } from '@/helpers/types/event/event.types';

export const types = {
  SET_IS_LOGIN: 'SET_IS_LOGIN',
  SET_LIST_ROLES: 'SET_LIST_ROLES',
  SET_REGISTRATION_FORM: 'SET_REGISTRATION_FORM',
  SET_IS_FIELDS_EXIST: 'SET_IS_FIELDS_EXIST',
  SET_IS_REFRESHING: 'SET_IS_REFRESHING',
  SET_RECOVER_TOKEN: 'SET_RECOVER_TOKEN',
  SET_SEARCH_CITIES: 'SET_SEARCH_CITIES',
  SET_SEARCH_REGIONS: 'SET_SEARCH_REGIONS',
  SET_SEARCH_COUNTRIES: 'SET_SEARCH_COUNTRIES',
  SET_SEARCH_MUNICIPALITIES: 'SET_SEARCH_MUNICIPALITIES',
  SET_IS_CITIES_LOADING: 'SET_IS_CITIES_LOADING',
  SET_IS_REGIONS_LOADING: 'SET_IS_REGIONS_LOADING',
  SET_IS_COUNTRIES_LOADING: 'SET_IS_COUNTRIES_LOADING',
  SET_IS_MUNICIPALITIES_LOADING: 'SET_IS_SET_SEARCH_MUNICIPALITIES_LOADING',
  CLEAR_REGIONS: 'CLEAR_REGIONS',
  CLEAR_CITIES: 'CLEAR_CITIES',
  CLEAR_COUNTRIES: 'CLEAR_COUNTRIES',
  CLEAR_MUNICIPALITIES: 'CLEAR_MUNICIPALITIES',
};

export default {
  [types.SET_IS_LOGIN](state: IState, isLogin: boolean): void {
    state.isLogin = isLogin;
  },
  [types.SET_LIST_ROLES](state: IState, listRoles: Array<string>): void {
    state.listRoles = listRoles;
  },
  [types.SET_REGISTRATION_FORM](state: IState, registrationForm: TEventSignUpFormResponse): void {
    state.registrationForm = registrationForm;
  },
  [types.SET_IS_FIELDS_EXIST](state: IState, isFieldsExist: boolean): void {
    state.isFieldsExist = isFieldsExist;
  },
  [types.SET_IS_REFRESHING](state: IState, isRefreshing: boolean): void {
    state.isRefreshing = isRefreshing;
  },
  [types.SET_RECOVER_TOKEN](state: IState, recoverToken: string): void {
    state.recoverToken = recoverToken;
  },
  [types.SET_SEARCH_CITIES](state: IState, searchCities: Array<ICity>) {
    state.searchCities = searchCities;
  },
  [types.SET_SEARCH_REGIONS](state: IState, searchRegions: Array<IRegion>) {
    state.searchRegions = searchRegions;
  },
  [types.SET_SEARCH_COUNTRIES](state: IState, searchCountries: Array<ICountry>) {
    state.searchCountries = searchCountries;
  },
  [types.SET_SEARCH_MUNICIPALITIES](state: IState, searchMunicipalities: Array<string>) {
    state.searchMunicipalities = searchMunicipalities;
  },
  [types.SET_IS_CITIES_LOADING](state: IState, isLoading: boolean) {
    state.isCitiesLoading = isLoading;
  },
  [types.SET_IS_REGIONS_LOADING](state: IState, isLoading: boolean) {
    state.isRegionsLoading = isLoading;
  },
  [types.SET_IS_COUNTRIES_LOADING](state: IState, isLoading: boolean) {
    state.isCountriesLoading = isLoading;
  },
  [types.SET_IS_MUNICIPALITIES_LOADING](state: IState, isLoading: boolean) {
    state.isMunicipalitiesLoading = isLoading;
  },

  [types.CLEAR_REGIONS](state: IState) {
    state.searchRegions = [];
  },
  [types.CLEAR_CITIES](state: IState) {
    state.searchCities = [];
  },
  [types.CLEAR_COUNTRIES](state: IState) {
    state.searchCountries = [];
  },
  [types.CLEAR_MUNICIPALITIES](state: IState) {
    state.searchMunicipalities = [];
  },
};
