<template>
  <AppModal :is-show-modal="true" @toggleModal="$emit('close-modal')" text-head="Авторизация">
    <form @submit.prevent="login.call(null)">
      <AppSocialNetworks />
      <AppInput
        v-model="form.email"
        label="E-mail"
        placeholder="example@mail.com"
        :errors="errors.email"
        name="username"
        class="app-modal-auth__input-email"
        @onInput="onInput('email', $event)"
      />
      <AppInput
        v-model="form.password"
        label="Пароль"
        placeholder="Введите пароль"
        :type="isPasswordHidden ? 'password' : 'text'"
        :errors="errors.password"
        name="password"
        class="app-modal-auth__input-password"
        @onInput="onInput('password', $event)"
      >
        <template #append>
          <div
            class="app-modal-auth__password-controller"
            @click="isPasswordHidden = !isPasswordHidden"
          >
            <app-icon :name="isPasswordHidden ? 'eye-off' : 'eye'" />
          </div>
        </template>
      </AppInput>
      <AppButton text="Продолжить" web-type="submit" :is-disabled="login.isLoading.value" />
    </form>

    <div class="app-modal-auth__forgot-container">
      <AppText type="primary-book" color="secondary" :text="'Забыли пароль?'" />
      <div @click="openRecoveryModal" class="app-modal-auth__text">
        <AppText type="primary-book" color="text-link" :text="'Восстановите здесь'" />
      </div>
    </div>
  </AppModal>
</template>

<script lang="ts">
export default { name: 'AuthModal' };
</script>

<script setup lang="ts">
import { AppSocialNetworks } from '@/components/modals';
import AppModal from '@/ui/components/modals/app-modal';
import { AppButton, AppIcon, AppInput, AppText } from '@/ui/components';
import { useApiCall } from '@/helpers/composables/api-call.composable';
import { AuthApi } from '@/api/apis/Auth.api';
import { JwtTokensHelper } from '@/helpers/classes/jwt-tokens-helper.class';
import { EX_$Cookies } from '@/classes/base';
import { useAuthStore } from '@/store/pinia/auth/auth.store';
import EX_Toaster from '@/classes/content/toast';
import { YandexMetrikaUtil } from '@/utils/yandex-metrika.util';
import { StatisticsApi } from '@/api/apis/StatisticsApi';
import { ref } from 'vue';
import store from '@/store';
import { useRoute } from 'vue-router/composables';

const emit = defineEmits<{
  (e: 'close-modal'): void;
  (e: 'open-recovery-modal'): void;
}>();

const route = useRoute();

const form = ref({
  email: '',
  password: '',
});

const errors = ref({
  email: [],
  password: [],
});

const isPasswordHidden = ref(false);

const onInput = (key: 'email' | 'password', value: string) => {
  form.value[key] = value;
  errors.value[key] = [];
};

const login = useApiCall(
  async () => {
    const res = await AuthApi.loginByEmailAndPassword({
      email: form.value.email.trim().toLowerCase(),
      password: form.value.password.trim(),
    });

    JwtTokensHelper.setAccessToken(res.accessToken);
    JwtTokensHelper.setRoleInfo(res.accessToken);
    JwtTokensHelper.setRefreshToken(res.refreshToken);

    if (process.env.VUE_APP_ENV_STATE === 'LOCAL')
      EX_$Cookies.set('refreshToken', res.refreshToken);

    await store.dispatch('auth/ASetIsLogin', true);
    useAuthStore().setIsLoggedIn(true);

    emit('close-modal');
    await store.dispatch('event/AGetMyEventList');
    EX_Toaster.success('Успешная авторизация');

    if (route.name === 'EventPage') YandexMetrikaUtil.reachGoalVisitEventPage();
    if (route.name === 'EventPage') StatisticsApi.visitEventPage();
  },
  false,
  (e) => {
    const errors = e?.response?.data?.errors;
    if (errors) {
      errors.value = errors;
      return;
    }

    const message = e?.response?.data?.message;
    if (message) EX_Toaster.error(message);
  }
);

const openRecoveryModal = () => {
  emit('close-modal');
  emit('open-recovery-modal');
};
</script>

<style scoped lang="scss"></style>
