var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{staticClass:"box-border flex flex-col gap-1",attrs:{"id":_vm.id}},[_c(_setup.UILabel,{attrs:{"size":_vm.size},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("label")]},proxy:true},{key:"label-info",fn:function(){return [_vm._t("label-info")]},proxy:true}],null,true)}),_c('span',{staticClass:"w-full relative flex"},[(_setup.checkIsSlotFilled(_vm.$slots.leftPlace))?_c('span',{ref:"leftPlaceRef",staticClass:"absolute top-1/2 transform -translate-y-1/2 z-[1]",style:({
        left: `${_setup.DEFAULT_PADDING[_vm.size]}px`,
      })},[_vm._t("leftPlace")],2):_vm._e(),_c(_vm.tag,_vm._b({tag:"component",staticClass:"appearance-none box-border w-full flex items-center border-none font-base bg-neutral-100 placeholder:font-base placeholder:text-gray focus:text-black-50 focus:outline-blue z-0",class:{
        'bg-white': _vm.backgroundWhite,

        'bg-red-50 focus:outline-red-50': _vm.errorMessages?.length,

        'resize-y': _vm.tag === 'textarea',

        'h-[64px] text-lg/7 rounded placeholder:text-lg/7': _vm.size === 'extra-large',
        'h-[56px] text-base/4 rounded-md placeholder:text-base/4': _vm.size === 'large',
        'h-[46px] text-lg/5 rounded-sm placeholder:text-lg/5': _vm.size === 'medium',
        'h-[44px] text-lg/5 rounded-sm placeholder:text-lg/5': _vm.size === 'small',
        'h-[32px] text-sm/2 rounded-sm placeholder:text-sm/2': _vm.size === 'extra-small',

        'rounded-[50px]':
          _vm.round && (_vm.size === 'extra-large' || _vm.size === 'large' || _vm.size === 'medium'),

        'rounded-[46px]': _vm.round && _vm.size === 'small',
        'h-auto': _vm.tag === 'textarea',
      },style:({
        paddingLeft: `${_setup.paddingLeft}px`,
        paddingRight: `${_setup.paddingRight}px`,
        paddingTop: _vm.tag === 'textarea' ? `${_setup.DEFAULT_PADDING[_vm.size]}px` : 'unset',
        paddingBottom: _vm.tag === 'textarea' ? `${_setup.DEFAULT_PADDING[_vm.size]}px` : 'unset',
      }),attrs:{"id":_vm.inputId,"value":_vm.value,"type":_vm.nativeType,"maxlength":_vm.maxLength,"inputmode":_setup.inputMode,"placeholder":_vm.placeholder,"rows":_vm.textAreaRows},on:{"input":_setup.onInput,"keydown":function($event){return _vm.$emit('keydown', $event)},"paste":function($event){return _vm.$emit('paste', $event)},"blur":function($event){return _vm.$emit('blur', $event)},"change":function($event){return _vm.$emit('change', $event)}}},'component',_vm.$attrs,false),[(_vm.tag === 'textarea')?[_vm._v(_vm._s(_vm.value))]:_vm._e()],2),(_vm.errorMessages?.length)?_c('span',{ref:"iconsRefRight",staticClass:"absolute",class:{
        'top-1/2 transform -translate-y-1/2': _vm.tag === 'input',
        'top-5': _vm.tag === 'textarea',
      },style:({
        right: `${_setup.DEFAULT_PADDING[_vm.size]}px`,
      })},[_c(_setup.UIIcon,{staticClass:"stroke-red-500 fill-none",attrs:{"name":"info"}})],1):_vm._e(),(_setup.checkIsSlotFilled(_vm.$slots.rightPlace) && !_vm.errorMessages?.length)?_c('span',{ref:"rightPlaceRef",staticClass:"absolute top-1/2 transform -translate-y-1/2",style:({
        right: `${_setup.DEFAULT_PADDING[_vm.size]}px`,
      })},[_vm._t("rightPlace")],2):_vm._e()],1),(_vm.maxLength !== undefined && _vm.isMaxLengthVisible)?_c('span',{staticClass:"text-gray text-xs"},[_vm._v(" "+_vm._s(String(_vm.value).length)+" / "+_vm._s(_vm.maxLength)+" ")]):_vm._e(),_c(_setup.UIErrorMessages,{attrs:{"error-messages":_vm.errorMessages}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }