import { render, staticRenderFns } from "./SignUpModal.vue?vue&type=template&id=40b2377d&scoped=true"
import script from "./SignUpModal.vue?vue&type=script&setup=true&lang=ts"
export * from "./SignUpModal.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SignUpModal.vue?vue&type=style&index=0&id=40b2377d&prod&lang=scss"
import style1 from "./SignUpModal.vue?vue&type=style&index=1&id=40b2377d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40b2377d",
  null
  
)

export default component.exports