<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    :is-hide-icon-close="true"
  >
    <div class="app-modal-start__cart" :style="getBackground">
      <div class="app-modal-start__cart-shadow">
        <AppText
          class="app-modal-start__event-name"
          type="fourthTitle"
          color="button-title"
          :text="eventInfo.title"
        />
      </div>
    </div>

    <div v-if="isShowRegistration ? title.base : title.onlyLogin" class="app-modal-start__title">
      <AppText type="title" :text="isShowRegistration ? title.base : title.onlyLogin" />
    </div>

    <div v-if="isShowRegistration ? text.base : text.onlyLogin" class="app-modal-start__subtitle">
      <AppText
        type="primary-book"
        color="secondary"
        :text="isShowRegistration ? text.base : text.onlyLogin"
      />
    </div>

    <EventSignUp v-if="isShowRegistration">
      <template #control="{ startSignUp }">
        <AppButton text="Зарегистрироваться" @click="startSignUp()" />
      </template>
    </EventSignUp>

    <AppButton
      class="app-modal-start__button-login"
      type="secondary"
      text="Войти"
      :is-disabled="false"
      @click="openAuthModal"
    />
  </AppModal>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import AppModal from '@/ui/components/modals/app-modal';
import AppButton from '@/ui/components/buttons/app-button/AppButton.vue';
import AppText from '@/ui/components/text/app-text/AppText.vue';
import { MWidthWindow } from '@/mixins';
import { mapActions, mapState } from 'vuex';
import { EX_$Modal } from '@/classes/content';
import EventSignUp from '@/components/event/shared/EventSignUp/EventSignUp.vue';

export default defineComponent({
  name: 'AppModalStart',
  components: { EventSignUp, AppModal, AppButton, AppText },
  mixins: [MWidthWindow],
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
    title: {
      type: Object as PropType<{ base: string; onlyLogin: string }>,
      required: false,
      default: () => {
        return { base: 'Станьте участником события', onlyLogin: 'Авторизируйтесь' };
      },
    },
    text: {
      type: Object as PropType<{ base: string; onlyLogin: string }>,
      required: false,
      default: () => {
        return {
          base: 'Регистрируйтесь, чтобы не пропустить его начало и получить максимум пользы',
          onlyLogin: '',
        };
      },
    },
  },
  data() {
    return {
      EX_$ModalClass: EX_$Modal,
      isPassHidden: true,
    };
  },
  methods: {
    ...mapActions('auth', ['AGetRegistrationForm']),
    toggleModal() {
      return this.$emit('toggleModal');
    },
    openAuthModal() {
      this.toggleModal();
      this.EX_$ModalClass.toggleModal('appModalAuth');
    },
  },
  computed: {
    ...mapState('event', ['eventInfo']),
    getBackground(): string {
      return `background: url(${this.eventInfo.image}) center center/cover no-repeat;`;
    },
    isShowRegistration(): boolean {
      return (
        this.eventInfo?.isRegistrationRequired &&
        Date.now() >= Date.parse(this.eventInfo?.registrationStartDate) &&
        Date.now() <= Date.parse(this.eventInfo?.registrationEndDate)
      );
    },
  },
});
</script>

<style lang="scss">
@use 'style';
</style>
