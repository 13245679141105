<template>
  <div>
    <div class="layout">
      <header id="admin-header">
        <AdminHeader />
      </header>

      <main :style="{ backgroundColor: mainBackgroundColor }">
        <router-view />
      </main>

      <AdminSidebar v-if="$route.meta?.['sidebar']" :event-info="data" />

      <footer id="admin-footer">
        <AdminFooter />
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
export default { name: 'AdminLayout' };
</script>

<script setup lang="ts">
import AdminSidebar from '@/layouts/admin/AdminSidebar.vue';
import AdminHeader from '@/layouts/admin/AdminHeader.vue';
import AdminFooter from '@/layouts/admin/AdminFooter.vue';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router/composables';
import { tailwindTheme } from '@/helpers/configs/tailwind-theme.config';
import { IEventInfo } from '@/services/EventService/EventServiceTypes';
import { useApiCall } from '@/helpers/composables/api-call.composable';
import { getEventInfo } from '@/services/EventService/EventService';

const route = useRoute();

const grayBackgroundPages = [
  'UserManagementEventUsers',
  'UserManagementInvitedUsers',
  'UserManagementFullStatistics',
  'AdminPartners',
  'EventSettings',
  'AdminSpacePage',
  'AdminSpaceEditPage',
  'AdminSpaceEditSettingsPage',
  'AdminSpaceEditConstructorPage',
];
const mainBackgroundColor = computed(() => {
  const isGrayBackground = grayBackgroundPages.includes(String(route.name));
  return isGrayBackground ? tailwindTheme.colors.neutral['100'] : 'inherit';
});

const data = ref({} as IEventInfo);
const fetchData = useApiCall(async () => {
  data.value = await getEventInfo(route.params.slug);
}, true);

watch(
  () => route.params.slug,
  (newSlug) => {
    if (newSlug) {
      fetchData.call(null);
    }
  },
  { immediate: true }
);
</script>

<style lang="scss"></style>
