<template>
  <div class="box-border">
    <HeaderDesktop
      v-if="windowSizeStore.isWindow.desktop"
      variant="NAV_INLINE"
      color-variant="WHITE"
      :is-header-hidden="isHeaderHidden"
      :nav-items="navItems"
    />
    <HeaderMobile
      v-if="!windowSizeStore.isWindow.desktop"
      color-variant="WHITE"
      :nav-items="navItems"
    />
  </div>
</template>

<script lang="ts">
export default { name: 'Header' };
</script>

<script setup lang="ts">
import { useWindowSizeStore } from '@/store/pinia/window-size/window-size.store';
import HeaderMobile from '@/layouts/Header/Mobile/HeaderMobile.vue';
import HeaderDesktop from '@/layouts/Header/Desktop/HeaderDesktop.vue';
import store from '@/store';
import { computed } from 'vue';
import { useRoute } from 'vue-router/composables';
import { ROUTES_WITH_PAGE_CUSTOMIZATION } from '@/helpers/constants/shared/page-customization.constants';

defineProps<{
  isHeaderHidden: boolean;
}>();

const route = useRoute();

const windowSizeStore = useWindowSizeStore();

const navItems = computed(() => {
  const isRouteWithCustomization = ROUTES_WITH_PAGE_CUSTOMIZATION.includes(String(route.name));

  const clientNavItems = store.state.client.clientInfo?.header?.navItems ?? [];
  const customNavItems = store.state.event.eventInfo?.pageCustomization?.headerLinks ?? [];

  return isRouteWithCustomization && customNavItems.length ? customNavItems : clientNavItems;
});
</script>

<style scoped lang="scss"></style>
