import { IAuthTokens, IChangePassword } from '@/services/AuthService/AuthServiceTypes';
import clientHttp from '@/utils/custom/http/СlientHttp';

export const refreshTokens = (refreshToken: string | null): Promise<IAuthTokens> => {
  return clientHttp.post('/auth/refresh', { refreshToken: refreshToken });
};

export const changePassword = (payload: IChangePassword): Promise<IAuthTokens> => {
  return clientHttp.post('/auth/recover-password', payload);
};
