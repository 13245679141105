<template>
  <div class="app-input-dropdown">
    <app-input
      @onInput="search"
      :color="'secondary'"
      :disabled="disabled"
      :value="activeItem[listKey]"
      :label="label"
      :errors="errors"
      type="main"
      :placeholder="placeholder"
      :note="note"
      :is-have-empty-block="isHaveEmptyBlock"
    />

    <div
      v-if="isShowList"
      class="select-list"
      :class="{ scroll: list.length > 7 }"
      v-click-outside="closeList"
    >
      <div class="select-list__spinner" v-if="isLoading || initialDropdownOpen">
        <app-spinner size="small" />
      </div>
      <div v-else-if="!list || list.length === 0">
        <button class="select-list__item" disabled>
          <app-text text="Не найдено" />
        </button>
      </div>
      <div v-else>
        <button
          v-for="(item, index) in list"
          :key="index"
          class="select-list__item"
          :class="{ active: item[listKey] === activeItem[listKey] }"
          @click="changeActiveItem(item)"
        >
          <app-text v-if="item.title" :text="item.title" />
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import AppInput from '@/ui/components/inputs/app-input/AppInput.vue';
import AppSpinner from '@/components/common/app-spinner';
import { ISelectItem } from '@/ui/components/selects/app-multi-select/AppMultiSelectTypes';
import AppText from '@/ui/components/text/app-text/AppText.vue';

export default Vue.extend({
  components: {
    AppInput,
    AppText,
    AppSpinner,
  },
  props: {
    defaultValue: {
      type: Object,
      required: false,
    },
    listKey: {
      type: String,
      required: true,
    },
    value: {
      type: String,
    },
    placeholder: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
    },
    note: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    isLoading: Boolean,
    isHaveEmptyBlock: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    const activeItem: ISelectItem = {};
    return {
      isShowList: false,
      activeItem,
      //required for spinner appearing when list drops initially
      initialDropdownOpen: true,
    };
  },
  watch: {
    defaultValue() {
      this.activeItem = { ...this.defaultValue };
    },
    isLoading(state) {
      if (state) this.initialDropdownOpen = false;
    },
  },
  methods: {
    changeActiveItem(value: ISelectItem): void {
      if (this.activeItem[this.listKey] == value[this.listKey]) {
        this.activeItem = {};
      } else {
        this.activeItem = { ...value };
      }
      this.$emit('onSelect', value);
      this.closeList();
    },
    closeList(): void {
      this.isShowList = false;
    },
    showList() {
      this.isShowList = true;
    },
    search(value: string) {
      if (value.length >= 3) {
        this.showList();
      } else this.closeList();
      this.$emit('search', value);
      if (value.length < 3) {
        this.initialDropdownOpen = true;
      }
      if (value.length === 0) {
        this.activeItem[this.listKey] = '';
      }
    },
  },
  beforeMount() {
    this.activeItem = { ...this.defaultValue };
  },
});
</script>

<style lang="scss" scoped>
@import '~@/ui/styles/colors/index.scss';
@import '~@/ui/styles/font/style.scss';
@import '~@/ui/styles/theme/_color_theme.scss';

.app-input-dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  .input {
    .app-input {
      flex-direction: row;
    }
  }
  &__chevron-down {
    cursor: pointer;
    transition: 0.3s;
    display: inline-block;
  }
  &__chevron-down_active {
    transform: rotate(180deg);
  }
  .selected-block {
    .app-input {
      top: 8px;
      z-index: 3;
    }
    @include themed() {
      background-color: t(background_select_list);
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    border-radius: 12px;

    padding: 0 12px;
    @include themed() {
      border: 1px solid t(border_select_list);
    }
  }
  .select-list {
    display: grid;
    justify-content: flex-start;
    grid-template-columns: 100%;
    @include themed() {
      background-color: t(background_select_list);
    }
    border-radius: 12px;
    padding: 11px 5px;
    z-index: 99;
    position: absolute;
    width: calc(100% - 10px);
    top: 93px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    &::-webkit-scrollbar {
      width: 6px;
      &-thumb {
        border-radius: 6px;
        @include themed() {
          background-color: t(scrollbar);
        }
        border: 1px solid transparent;
        background-clip: content-box;
      }

      &-track {
        @include themed() {
          background-color: t(background_select_list);
        }
        background: white;
        border-radius: 6px;
      }
    }
    &.scroll {
      height: 230px;
      overflow-y: scroll;
    }

    &__item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 39px;
      padding: 0 0 0 11px;
      border-radius: 8px;
      .app-text {
        text-align: start;
      }
      @include themed() {
        border: t(background_select_list);
        background-color: t(background_select_list);
      }

      &.active {
        @include themed() {
          background-color: t(background_select_active_item);
        }
      }
    }
    &__spinner {
      height: 39px;
    }
  }
}
</style>
