<template>
  <AppModal :is-show-modal="true" :is-hide-icon-close="true" @toggleModal="$emit('close-modal')">
    <div class="w-full flex flex-col gap-5">
      <div class="app-modal-start__cart" :style="getBackgroundImage(eventHeroImageUrl)">
        <div class="app-modal-start__cart-shadow">
          <AppText
            class="app-modal-start__event-name"
            type="fourthTitle"
            color="button-title"
            :text="eventTitle"
          />
        </div>
      </div>

      <div v-if="additionalTitle" class="app-modal-start__title">
        <AppText type="title" :text="additionalTitle" />
      </div>

      <div v-if="additionalText" class="app-modal-start__subtitle">
        <AppText type="primary-book" color="secondary" :text="additionalText" />
      </div>

      <AppButton text="Зарегистрироваться" @click="clickSignUp" />

      <AppButton
        class="app-modal-start__button-login"
        type="secondary"
        text="Войти"
        :is-disabled="false"
        @click="clickAuth"
      />
    </div>
  </AppModal>
</template>

<script lang="ts">
export default { name: 'StartModal' };
</script>

<script setup lang="ts">
import AppModal from '@/ui/components/modals/app-modal';
import { AppButton, AppText } from '@/ui/components';
import { getBackgroundImage } from '@/helpers/methods/image.methods';

const props = defineProps<{
  eventTitle: string;
  eventHeroImageUrl: string;
  additionalTitle?: string;
  additionalText?: string;
}>();

const emit = defineEmits<{
  (e: 'close-modal'): void;
  (e: 'open-sign-up-modal'): void;
  (e: 'open-auth-modal'): void;
}>();

const clickSignUp = () => {
  emit('open-sign-up-modal');
  emit('close-modal');
};

const clickAuth = () => {
  emit('open-auth-modal');
  emit('close-modal');
};
</script>

<style scoped lang="scss"></style>
