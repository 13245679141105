<template>
  <label class="box-border flex flex-col gap-1" :id="id">
    <UILabel :size="size">
      <template #label><slot name="label" /></template>
      <template #label-info><slot name="label-info" /></template>
    </UILabel>

    <span class="w-full relative flex">
      <span
        v-if="checkIsSlotFilled($slots.leftPlace)"
        ref="leftPlaceRef"
        class="absolute top-1/2 transform -translate-y-1/2 z-[1]"
        :style="{
          left: `${DEFAULT_PADDING[size]}px`,
        }"
      >
        <slot name="leftPlace"></slot>
      </span>

      <component
        :is="tag"
        :id="inputId"
        :value="value"
        v-bind="$attrs"
        :type="nativeType"
        :maxlength="maxLength"
        :inputmode="inputMode"
        :placeholder="placeholder"
        :rows="textAreaRows"
        class="appearance-none box-border w-full flex items-center border-none font-base bg-neutral-100 placeholder:font-base placeholder:text-gray focus:text-black-50 focus:outline-blue z-0"
        :class="{
          'bg-white': backgroundWhite,

          'bg-red-50 focus:outline-red-50': errorMessages?.length,

          'resize-y': tag === 'textarea',

          'h-[64px] text-lg/7 rounded placeholder:text-lg/7': size === 'extra-large',
          'h-[56px] text-base/4 rounded-md placeholder:text-base/4': size === 'large',
          'h-[46px] text-lg/5 rounded-sm placeholder:text-lg/5': size === 'medium',
          'h-[44px] text-lg/5 rounded-sm placeholder:text-lg/5': size === 'small',
          'h-[32px] text-sm/2 rounded-sm placeholder:text-sm/2': size === 'extra-small',

          'rounded-[50px]':
            round && (size === 'extra-large' || size === 'large' || size === 'medium'),

          'rounded-[46px]': round && size === 'small',
          'h-auto': tag === 'textarea',
        }"
        :style="{
          paddingLeft: `${paddingLeft}px`,
          paddingRight: `${paddingRight}px`,
          paddingTop: tag === 'textarea' ? `${DEFAULT_PADDING[size]}px` : 'unset',
          paddingBottom: tag === 'textarea' ? `${DEFAULT_PADDING[size]}px` : 'unset',
        }"
        @input="onInput"
        @keydown="$emit('keydown', $event)"
        @paste="$emit('paste', $event)"
        @blur="$emit('blur', $event)"
        @change="$emit('change', $event)"
      >
        <template v-if="tag === 'textarea'">{{ value }}</template>
      </component>

      <span
        v-if="errorMessages?.length"
        ref="iconsRefRight"
        class="absolute"
        :class="{
          'top-1/2 transform -translate-y-1/2': tag === 'input',
          'top-5': tag === 'textarea',
        }"
        :style="{
          right: `${DEFAULT_PADDING[size]}px`,
        }"
      >
        <UIIcon name="info" class="stroke-red-500 fill-none" />
      </span>

      <span
        v-if="checkIsSlotFilled($slots.rightPlace) && !errorMessages?.length"
        ref="rightPlaceRef"
        class="absolute top-1/2 transform -translate-y-1/2"
        :style="{
          right: `${DEFAULT_PADDING[size]}px`,
        }"
      >
        <slot name="rightPlace"></slot>
      </span>
    </span>

    <span v-if="maxLength !== undefined && isMaxLengthVisible" class="text-gray text-xs">
      {{ String(value).length }} / {{ maxLength }}
    </span>

    <UIErrorMessages :error-messages="errorMessages" />
  </label>
</template>

<script lang="ts">
export default { name: 'UIInput', inheritAttrs: false };
// Ссылка на UI kit в макете https://www.figma.com/file/CRKluP0CXR31FcB24lvW8Q/%E2%9D%87%EF%B8%8F-%D0%9C%D0%9C%D0%A1%D0%9E.%D0%9A%D0%9E%D0%9D%D0%9D%D0%95%D0%9A%D0%A2?type=design&node-id=13419-121886&mode=dev
</script>

<script setup lang="ts">
import { UISize } from '@/helpers/types/ui.types';
import { computed, onMounted, ref } from 'vue';
import UIIcon from '@/components/UI/UIIcon/UIIcon.vue';
import { checkIsSlotFilled } from '@/helpers/methods/slots.method';
import UIErrorMessages from '@/components/UI/shared/UIErrorMessages.vue';
import UILabel from '@/components/UI/shared/UILabel.vue';

const props = withDefaults(
  defineProps<{
    value?: string | number;
    placeholder: string;
    size: UISize;
    // successMessages?: string[];
    errorMessages?: string[];
    maxLength?: number;
    // Выставляется false в случаях, когда мы должны не дать юзеру ввести больше символов чем нужно,
    // но отображать количество символов не нужно, Например при вводе номера телефона
    isMaxLengthVisible?: boolean;
    tag?: 'input' | 'textarea';
    textAreaRows?: number;
    onlyNumbers?: boolean;
    maxValue?: number;
    backgroundWhite?: boolean;
    round?: boolean;
    inputId?: string;
    id?: string;
    inputmode?: string;
    nativeType?: string;
  }>(),
  {
    isMaxLengthVisible: true,
    tag: 'input',
    textAreaRows: 4,
    backgroundWhite: false,
    inputId: undefined,
    id: undefined,
    inputmode: 'text',
    nativeType: 'text',
  }
);
const emits = defineEmits<{
  (event: 'input', value: string | number): void;
  (event: 'keydown', value: any): void;
  (event: 'paste', value: any): void;
  (event: 'blur', value: any): void;
  (event: 'change', value: InputEvent): void;
  (event: 'input-raw', value: InputEvent): void;
}>();

const onInput = (event: InputEvent) => {
  const target = event.target as HTMLInputElement;
  if (props.onlyNumbers) {
    target.value = target.value.replace(/\D/g, '');
  }

  if (inputMode.value === 'numeric') {
    if (checkMaxValue(target.value)) {
      target.value = String(props.maxValue);
      emits('input', Number(props.maxValue));
    } else {
      emits('input', Number(target.value));
    }
  } else {
    emits('input', target.value);
  }
  emits('input-raw', event);
};

const leftPlaceRef = ref<HTMLSpanElement | null>(null);
const rightPlaceRef = ref<HTMLSpanElement | null>(null);

const iconsRefRight = ref<HTMLSpanElement | null>(null);

const DEFAULT_PADDING = {
  'extra-large': 16,
  large: 16,
  medium: 10,
  middle: 10,
  small: 10,
  'extra-small': 10,
};
const paddingLeft = computed(() => {
  const defaultPadding = DEFAULT_PADDING[props.size];
  // добавляем еще один дефолтный отступ, так как у самого блока иконок тоже есть отступ справа
  const leftIconsResWidth = leftPlaceRef.value?.clientWidth
    ? leftPlaceRef.value.clientWidth + 8
    : 0;
  return defaultPadding + leftIconsResWidth;
});
const paddingRight = computed(() => {
  const defaultPadding = DEFAULT_PADDING[props.size];
  const iconWidth = iconsRefRight.value?.clientWidth ?? 0;
  const placeWidth = rightPlaceRef.value?.clientWidth ?? 0;
  // добавляем еще один дефолтный отступ, так как у самого блока иконок тоже есть отступ справа
  const rightIconsWidth = iconWidth || placeWidth ? defaultPadding + placeWidth + iconWidth : 0;
  return defaultPadding + rightIconsWidth;
});

const inputMode = computed(() => {
  if (typeof props.value === 'number' || props.onlyNumbers) {
    return 'numeric';
  } else {
    return props.inputmode;
  }
});
const checkMaxValue = (value: number | string) => {
  return (
    inputMode.value === 'numeric' && props.maxValue !== undefined && Number(value) > props.maxValue
  );
};
onMounted(() => {
  if (checkMaxValue(props.value ?? 0)) {
    emits('input', Number(props.maxValue));
  }
});
</script>

<style lang="scss" scoped></style>
